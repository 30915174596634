
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ProjectGeneralInfo from '@/components/views/NewProjectView/Overview/ProjectGeneralInfo.vue';
import {
    navigateToProjectsClientDetails,
    setFirstProjectOfferAsSelectedIfNoneIsSelected,
} from '@/helpers/NewProject/NewProjectHelper';
import ProjectShellComponent from '@/components/views/NewProjectView/ProjectShellComponent.vue';
import ClientDetailsAndForm from '@/components/views/NewProjectView/Overview/ClientDetailsAndForm.vue';
import ClientForm from '@/components/views/NewProjectView/Overview/ClientForm.vue';
import OfferHistoryNew from '@/components/views/project/OfferHistoryNew.vue';
import { Action } from 'vuex-class';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import Offer from '@/models/Offer';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { sortDates } from '@/helpers/SortingHelper';

@Component({
    name: 'ProjectTabOverview',
    components: { ClientDetailsAndForm, ProjectGeneralInfo, ProjectShellComponent, OfferHistoryNew },
})
export default class ProjectTabOverview extends Vue {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;
    @Prop({ required: true }) private projectId!: string;
    @Prop({ default: [] }) private loadedKeys!: Set<ProjectTabValues>;
    @Prop({ required: true }) private updateActiveKey!: (key: ProjectTabValues) => void;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    @Prop({ required: true }) private lockEditing!: boolean;
    @Prop({ required: false }) private basicProjectData!: any;

    public get isProjectLocked() {
        if (this.project == null) {
            return false;
        }
        return this.project.state === ProjectStates.ORDER || this.lockEditing;
    }

    public get isOfferLocked() {
        if (this.selectedOffer == null) {
            return false;
        }

        return this.selectedOffer.offerPdf !== null;
    }

    private get offerList() {
        // todo - temporary and should be reworked when we switch to the new design
        const sortedOffers = this.project.offerRevisions
            .slice()
            .sort((a, b) => sortDates(a.createdAt, b.createdAt, false));
        return [sortedOffers[0]];
    }

    private onOpenClient() {
        navigateToProjectsClientDetails(this.project?.projectClient ? this.project?.projectClient.id : null);
    }

    private onOpenProductsTab() {
        this.updateActiveKey(ProjectTabValues.Products);
    }

    private clientFormSaveAction() {
        const clientDetailsAndFormRef = this.$refs.clientDetailsAndForm as ClientDetailsAndForm;
        const clientFormRef = clientDetailsAndFormRef.$refs.clientForm as ClientForm;

        if (clientFormRef == null) {
            throw new Error('Client form reference does not exist');
        }

        return clientFormRef.saveForm();
    }

    private async created() {
        if (this.loadedKeys.has(ProjectTabValues.Basic)) {
            return;
        }
        this.$emit('update:isTabLoading', true);
        try {
            await setFirstProjectOfferAsSelectedIfNoneIsSelected(this.projectId);
            this.$emit('update:loadedKeys', new Set([...this.loadedKeys, ProjectTabValues.Basic]));
        } finally {
            this.$emit('update:isTabLoading', false);
        }
    }
}
