
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ClientSearch from '@/components/views/NewProjectView/Overview/ClientSearch.vue';
import ClientRepository from '@/repositories/ClientRepository';
import Client from '@/models/Client';
import { validateAndUpdateProjectClientAndContactPerson } from '@/helpers/NewProject/NewProjectHelper';
// tslint:disable-next-line
import ClientAndContactPersonDetails from '@/components/views/NewProjectView/Overview/ClientAndContactPersonDetails.vue';
import ContactPersonPicker from '@/components/views/NewProjectView/ContactPersonPicker.vue';
import OrderedByPicker from '@/components/views/NewProjectView/OrderedByPicker.vue';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'ClientForm',
    components: { ContactPersonPicker, ClientSearch, ClientAndContactPersonDetails, OrderedByPicker },
})
export default class ClientForm extends Vue {
    @Prop({ required: true }) private clientId!: string | null;
    @Prop({ required: true }) private contactPersonId!: string;
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: null }) private orderedBy!: number;

    private selectedClientId = this.clientId;
    private selectedContactPersonId: string | undefined = this.contactPersonId;

    public async saveForm() {
        try {
            await validateAndUpdateProjectClientAndContactPerson(
                this.projectId,
                this.selectedClientId,
                this.selectedContactPersonId
            );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private async beforeDestroy() {
        await Client.removeAllCurrentSearchResults();
    }

    @Watch('selectedClientId')
    private onSelectedClientChange() {
        const contactPersons = ClientRepository.contactPersons(this.selectedClientId);

        this.selectedContactPersonId = contactPersons.length <= 0 ? undefined : contactPersons[0].id;
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    public get canEditOrderedBy() {
        return this.userRights.includes(UserRightsEnum.AGENT_USER_RIGHT);
    }
}
