
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button } from 'ant-design-vue';
import Client from '@/models/Client';
import { Action } from 'vuex-class';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEvents } from '@/enums/global/PopupEvents';

@Component({
    name: 'CreateOrderButton',
    components: { Button },
})
export default class CreateOrderButton extends Vue {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;

    @Prop({ default: false }) private offerHasErrors!: boolean;
    @Prop({ default: true }) private showIcon!: boolean;
    @Prop({ default: null }) private selectedOfferId!: string;
    @Prop({ default: null }) private client!: Client | null;

    private async openConfirmModal() {
        EventBus.$emit(EventBusEvents.onOpenPdfPreviewOrderPopup, {
            popupEvent: PopupEvents.openPdfPreviewOrderPopup,
            data: {
                selectedOfferId: this.selectedOfferId,
                client: this.client,
            },
        });
    }
}
