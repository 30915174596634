
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button } from 'ant-design-vue';
import { onSendPDF } from '@/helpers/OfferHelper';
import Client from '@/models/Client';
import OfferRepository from '@/repositories/OfferRepository';
import ContactPerson from '@/models/ContactPerson';
import UserGroup from '@/models/UserGroup';
import { Action } from 'vuex-class';

@Component({
    name: 'SendPDFButton',
    components: {Button},
})
export default class SendPDFButton extends Vue {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;

    @Prop({default: false}) private offerHasErrors!: boolean;
    @Prop({default: null}) private selectedOfferId!: string | null;
    @Prop({default: null}) private client!: Client | null;
    @Prop({default: null}) private contactPerson!: ContactPerson | null;
    @Prop({default: null}) private currentUserGroup!: UserGroup | null;
    @Prop({default: ''}) private projectConnection!: string;

    private async onSendPDF() {
        const offer = OfferRepository.getOfferById(this.selectedOfferId);

        if (offer == null) {
            return;
        }

        this.updateAreOffersLoading(true);

        try {
            await onSendPDF({
                offer,
                contactPerson: this.contactPerson,
                currentUserGroup: this.currentUserGroup,
                client: this.client,
                projectConnection: this.projectConnection,
            });
        } finally {
            this.updateAreOffersLoading(false);
        }
    }

}
