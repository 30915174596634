
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button } from 'ant-design-vue';
import CreateOfferButton from '@/components/views/project/CreateOfferButton.vue';
import Client from '@/models/Client';
import CreateOrderButton from '@/components/views/project/CreateOrderButton.vue';
import ToggleRoltekRequestButton from '@/components/views/project/ToggleRoltekRequestButton.vue';
import FinishRoltekRequestButton from '@/components/views/project/FinishRoltekRequestButton.vue';
import CopyOfferButtonNew from '@/components/views/project/CopyOfferButtonNew.vue';
import ContactPerson from '@/models/ContactPerson';
import SendPDFButton from '@/components/views/project/SendPDFButton.vue';
import UserGroup from '@/models/UserGroup';

@Component({
    name: 'OfferHistoryActions',
    components: {
        SendPDFButton,
        CopyOfferButtonNew,
        CreateOrderButton,
        Button,
        CreateOfferButton,
        ToggleRoltekRequestButton,
        FinishRoltekRequestButton,
    },
})
export default class OfferHistoryActions extends Vue {
    @Prop({ default: false }) private offerHasErrors!: boolean;
    @Prop({ default: false }) private isAssistanceRequired!: boolean;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ default: null }) private projectId!: string | null;
    @Prop({ default: null }) private client!: Client | null;
    @Prop({ default: false }) private isProjectInRequestState!: boolean;
    @Prop({ default: null }) private contactPerson!: ContactPerson | null;
    @Prop({ default: null }) private currentUserGroup!: UserGroup | null;
    @Prop({ default: '' }) private projectConnection!: string;
    @Prop({ default: false }) private isOfferLocked!: boolean;
    @Prop({ default: false }) private isProjectLocked!: boolean;
    @Prop({ default: false }) private isProjectReadOnly!: boolean;
    @Prop({ default: false }) private isProjectFromAnotherUserGroup!: boolean;
    @Prop({ default: false }) private shouldShowOnlyCreateOfferButton!: boolean;
    @Prop({ default: false }) private isRoltek!: boolean;
}
