
import { Component, Prop, Vue } from 'vue-property-decorator';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import ClientRepository from '@/repositories/ClientRepository';
import Client from '@/models/Client';
import { getFormattedClientAddress } from '@/helpers/NewProject/NewProjectHelper';
import ContactPersonRepository from '@/repositories/ContactPersonRepository';

@Component({
    name: 'ClientAndContactPersonDetails',
    components: { TwoRowWithTag },
})
export default class ClientAndContactPersonDetails extends Vue {
    @Prop({ default: null }) private clientId!: string | null;
    @Prop({ default: null }) private contactPersonId!: string | null;
    @Prop({ required: false }) private basicProjectData!: any;

    private get client() {
        return ClientRepository.getById(this.clientId) as Client;
    }

    private get contactPerson() {
        return ContactPersonRepository.getById(this.contactPersonId);
    }

    private get clientAddress() {
        if (this.client) {
            return getFormattedClientAddress(this.client);
        }

        if (this.basicProjectData) {
            return (
                this.basicProjectData?.clientStreetNameAndNumber.trim() +
                ', ' +
                this.basicProjectData?.clientPoNumber.trim() +
                this.basicProjectData?.clientCity.trim()
            );
        }
    }

    private get contactPersonPhoneNumber() {
        if (this.contactPerson) {
            return this.contactPerson && this.contactPerson.phone ? this.contactPerson.phone : '-';
        }

        if (this.basicProjectData) {
            return this.basicProjectData?.contactPersonPhone ?? '-';
        }
    }

    private get contactPersonEmail() {
        return this.contactPerson && this.contactPerson.email ? this.contactPerson.email : '-';
    }
}
