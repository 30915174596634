
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button } from 'ant-design-vue';
import { cancelRoltekControl, finishRoltekControl, requestRoltekControl } from '@/helpers/OfferHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import OfferRepository from '@/repositories/OfferRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { Action } from 'vuex-class';
import Offer from '@/models/Offer';

@Component({
    name: 'ToggleRoltekRequestButton',
    components: { Button },
})
export default class ToggleRoltekRequestButton extends Vue {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;

    @Prop({ default: false }) private isAssistanceRequired!: boolean;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ default: false }) private isOfferLocked!: boolean;
    @Prop({ default: false }) private isProjectLocked!: boolean;
    @Prop({ default: false }) private isProjectFromAnotherUserGroup!: boolean;
    @Prop({ default: false }) private isRoltek!: boolean;

     private async onRequestControl() {
        const offer = OfferRepository.getOfferById(this.selectedOfferId);

        if (offer == null) {
            return;
        }
        this.updateAreOffersLoading(true);

        try {
            await finishRoltekControl(offer);
            await Offer.getForOffersTab(offer.id);
        } finally {
            this.updateAreOffersLoading(false);
        }

        EventBus.$emit(EventBusEvents.updateProject);
    }
}
