
import { Component, Prop } from 'vue-property-decorator';
import Label from '@/models/Label';
import OrderStatus from '@/models/OrderStatus';
import { generateActiveStatus, generateProgressBarPercentage } from '@/helpers/NewProject/NewProjectHelper';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { Progress } from 'ant-design-vue';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';

@Component({
    name: 'ActiveStatus',
    components: { Progress },
})
export default class ActiveStatus extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: null }) private label!: Label | null;
    @Prop({ default: null }) private orderStatus!: OrderStatus | null;
    @Prop({ default: false }) private isProjectInRequestState!: boolean;
    @Prop({ default: ProjectStates.LEAD }) private projectState!: ProjectStates;
    @Prop({ required: false }) private basicProjectData!: any;

    private get activeStatus() {
        return generateActiveStatus(
            this.label?.name ?? this.basicProjectData?.projectLabelName ?? '',
            this.orderStatus?.name ?? this.basicProjectData?.orderStatusName ?? '',
            this.isProjectInRequestState,
            this.isCurrentUserGroupRoltek
        );
    }

    private get progressPercent() {
        return generateProgressBarPercentage(this.projectState);
    }
}
