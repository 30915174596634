import i18n from '@/i18n';
import Offer from '@/models/Offer';
import {
    copyOfferToNewProject,
    deleteOfferAndSetSelectedOfferToLatest,
    extractOfferTitlesAsString,
    openDocumentAndPdfTypePopup,
    sendOfferXML,
    validateAndRecalculateOffer,
} from '@/helpers/OfferHelper';
import { OfferStates } from '@/enums/global/OfferStates';
import { CustomOfferHistoryStates } from '@/enums/components/Project/CustomOfferHistoryStates';
import OfferRepository from '@/repositories/OfferRepository';
import { OfferHistoryTableActionItemData } from '@/interfaces/components/offerHistory/OfferHistoryTableActionItemData';
import { ItemActionGeneratorParameters } from '@/interfaces/components/offerHistory/ItemActionGeneratorParameters';

export function tableColumns(isPreviewMode: boolean) {
    return [
        {
            title: i18n.t('Naziv'),
            dataIndex: 'name',
            slots: { title: 'name' },
            scopedSlots: { customRender: 'name' },
            width: 170,
        },
        {
            title: i18n.t('Datum izrade'),
            dataIndex: 'dateCreated',
            slots: { title: 'dateCreated' },
            scopedSlots: { customRender: 'dateCreated' },
            width: 170,
        },
        {
            title: i18n.t('Opis'),
            dataIndex: 'description',
            slots: { title: 'description' },
            scopedSlots: { customRender: 'description' },
        },
        {
            title: i18n.t('Cijena'),
            dataIndex: 'price',
            slots: { title: 'price' },
            scopedSlots: { customRender: 'price' },
            width: 180,
        },
        {
            title: i18n.t('Status ponude'),
            dataIndex: 'state',
            slots: { title: 'state' },
            scopedSlots: { customRender: 'state' },
            align: 'center',
            width: 130,
        },
        {
            title: i18n.t('Poslano klijentu'),
            dataIndex: 'sentToClient',
            slots: { title: 'sentToClient' },
            scopedSlots: { customRender: 'sentToClient' },
            align: 'center',
            width: 170,
        },
        {
            title: i18n.t('Akcije'),
            dataIndex: 'actions',
            slots: { title: 'actions' },
            scopedSlots: { customRender: 'actions' },
            align: 'right',
            width: isPreviewMode ? 80 : 220,
        },
    ];
}

export function customOfferStateMapping() {
    return {
        [CustomOfferHistoryStates.VALID]: {
            label: i18n.t('Without errors'),
            color: 'lime',
        },
        [CustomOfferHistoryStates.IN_CONTROL]: {
            label: i18n.t('In Roltek control'),
            color: 'blue',
        },
        [CustomOfferHistoryStates.TO_CONTROL]: {
            label: i18n.t('With errors'),
            color: 'red',
        },
        [CustomOfferHistoryStates.CREATED]: {
            label: i18n.t('Created'),
            color: 'lime',
        },
        [CustomOfferHistoryStates.ORDERED]: {
            label: i18n.t('Ordered'),
            color: 'green',
        },
    };
}

/**
 * Transforms the offer list to a structure that the table can use
 * @param offerList - The list of offers fetched from the backend
 * @param isProjectInRequestState
 * @return - An array of objects for all the columns in the offer table
 */
export function transformOfferListToTableList(offerList: Offer[], isProjectInRequestState: boolean) {
    return offerList.map((offer) => {
        return {
            id: offer.id,
            key: offer.id,
            name: offer.name,
            dateCreated: offer.createdAt,
            description: generateOfferDescription(offer),
            price:
                offer.state === OfferStates.IMPORTED && offer.importedPrice
                    ? offer.importedPrice
                    : offer.sellingOfferPrice.priceWithTax,
            state: determineCustomOfferHistoryState(offer),
            offerState: offer.state,
            hasPDF: offer.pdf != null,
            hasErrors: offerHasErrors(offer, isProjectInRequestState),
            offerItemsExist: offer.offerItems.length > 0,
            sentToClient: offer.sentToClient,
            assistanceRequired: offer.assistanceRequired,
        };
    });
}

/**
 * Generates the offer description from the offer
 * @param offer - The offer to be used for generating the description
 * @return - The offer's comment if it exists, otherwise all offer titles, otherwise an empty string
 */
export function generateOfferDescription(offer: Offer) {
    return offer.comment || extractOfferTitlesAsString(offer.offerTitles) || '';
}

/**
 * Determines the custom offer state for the offer history table
 * @param offer - The offer to be used for generating the state
 * @return
 * Returns IN_CONTROL if assistance is required
 * Returns TO_CONTROL if any of offer items in the offer has errors and errors are not allowed
 * Returns CREATED if the offers has a generated PDF or is imported from Ingenious
 * Returns ORDERED if the offer was ordered
 * Returns VALID if the offer is in draft state and has no errors or errors were allowed
 */
export function determineCustomOfferHistoryState(offer: Offer) {
    if (offer.state === OfferStates.LOCKED || offer.state === OfferStates.IMPORTED) {
        return CustomOfferHistoryStates.CREATED;
    }
    if (offer.state === OfferStates.ORDERED) {
        return CustomOfferHistoryStates.ORDERED;
    }
    if (offer.assistanceRequired) {
        return CustomOfferHistoryStates.IN_CONTROL;
    }
    if (offer.offerItems.some((offerItem) => offerItem.hasErrors && !offerItem.allowErrors)) {
        return CustomOfferHistoryStates.TO_CONTROL;
    }
    if (offer.state === OfferStates.DRAFT) {
        return CustomOfferHistoryStates.VALID;
    }
}

/**
 * Generate the offer item actions in the table
 * @param params - Offer and project options for deciding whether an item action should be visible
 * @return All items that are visible in the table row
 */
export function generateItemActions(params: ItemActionGeneratorParameters) {
    return [
        {
            label: i18n.t('Kopiraj ponudu u novi projekt'),
            key: 1,
            callback: async (options: OfferHistoryTableActionItemData) => {
                const offer = OfferRepository.getOfferById(options.offerId);

                if (offer == null) {
                    return;
                }

                await copyOfferToNewProject(offer);
            },
            isVisible: !params.isProjectLocked || (params.isProjectLocked && !params.isProjectReadOnly),
        },
        {
            label: i18n.t('Rekalkuliraj'),
            key: 2,
            callback: async (options: OfferHistoryTableActionItemData) => {
                await validateAndRecalculateOffer(options.offerId, options.client, true);
            },
            isVisible: !params.isOfferLocked && !params.isProjectReadOnly,
        },
        {
            label: i18n.t('Promijeni PDF ponude'),
            key: 3,
            callback: async (options: OfferHistoryTableActionItemData) => {
                const offer = OfferRepository.getOfferById(options.offerId);

                if (offer == null || options.projectId == null) {
                    return;
                }

                await openDocumentAndPdfTypePopup(options.projectId, offer);
            },
            isVisible:
                params.isOfferLocked && !params.isProjectReadOnly && !params.offerHasErrors && !params.isProjectLocked,
        },
        {
            label: i18n.t('Pošalji XML ponude'),
            key: 4,
            callback: async (options: OfferHistoryTableActionItemData) => {
                const offer = OfferRepository.getOfferById(options.offerId);

                if (offer == null) {
                    return;
                }

                await sendOfferXML(offer);
            },
            isVisible: params.canUserSendOrders && params.offerItemsExist,
        },
        {
            label: i18n.t('Obriši ponudu'),
            key: 5,
            callback: async (options: OfferHistoryTableActionItemData) => {
                if (options.projectId == null) {
                    return;
                }

                await deleteOfferAndSetSelectedOfferToLatest(
                    options.projectId as string,
                    options.offerId,
                    options.selectedOfferId
                );
            },
            isVisible:
                !params.isProjectLocked &&
                params.isOfferInDraftState &&
                !params.isOfferInRequestState &&
                !params.isProjectFromAnotherUserGroup &&
                params.canUserDeleteOffers,
        },
        {
            label: i18n.t('Download offer PDF specifications'),
            key: 6,
            callback: async (options: OfferHistoryTableActionItemData) => {
                const offer = OfferRepository.getOfferById(options.offerId);

                if (offer == null || options.projectId == null) {
                    return;
                }

                openDocumentAndPdfTypePopup(options.projectId, offer, false, true, params.isProjectLocked);
            },
            isVisible: true,
        },
    ];
}

/**
 * Checks if the given offer has errors in offer items, taking into account if it allows errors and if the
 * project is in request state
 * @param offer - The given offer
 * @param isProjectInRequestState - Is the project in request state
 * @return A boolean whether the offer has errors
 */
export function offerHasErrors(offer: Offer, isProjectInRequestState: boolean) {
    return offerItemsHaveErrors(offer) || isProjectInRequestState;
}

/**
 * Checks if the offer items in the offer have an error
 * project is in request state
 * @param offer - The given offer
 * @return A boolean whether any offer item has an error
 */
export function offerItemsHaveErrors(offer: Offer) {
    return offer.offerItems.some((offerItem) => offerItem.hasErrors && !offerItem.allowErrors);
}
