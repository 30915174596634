
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button } from 'ant-design-vue';
import { copyOfferInCurrentProject } from '@/helpers/OfferHelper';
import OfferRepository from '@/repositories/OfferRepository';
import { Action } from 'vuex-class';

@Component({
    name: 'CopyOfferButtonNew',
    components: {Button},
})
export default class CopyOfferButtonNew extends Vue {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;

    @Prop({default: null}) private selectedOfferId!: string | null;
    @Prop({default: false}) private isProjectInRequestState!: boolean;
    @Prop({default: false}) private isProjectLocked!: boolean;
    @Prop({default: false}) private isProjectReadOnly!: boolean;

    private isLoading = false;

    private async copyOffer() {
        const offer = OfferRepository.getOfferById(this.selectedOfferId);

        if (offer == null) {
            return;
        }

        this.updateAreOffersLoading(true);
        this.isLoading = true;

        try {
            await copyOfferInCurrentProject(offer);
        } catch (e) {
            this.updateAreOffersLoading(false);
            this.isLoading = false;
        }

        this.isLoading = false;
    }

}
