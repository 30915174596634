
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Radio } from 'ant-design-vue';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import Project from '@/models/Project';
import { OrderedByOptions } from '@/interfaces/components/project/OrderedByOptions';

@Component({
    name: 'OrderedByPicker',
    components: {
        TwoRowWithTag,
        RadioGroup: Radio.Group,
        RadioButton: Radio.Button,
    },
})
export default class OrderedByPicker extends Vue {
    @Prop({default: null}) private orderedBy!: number;
    @Prop({default: false}) private disabled!: boolean;
    @Prop({default: null}) private projectId!: string;
    private orderedByOptions = OrderedByOptions;
    private orderedByValue!: number;

    private data(){
        return {
            orderedByValue: this.orderedBy,
        };
    }

    private async setOrderedBy() {
        try {
            await Project.updateExisting({
                projectId: this.projectId,
                orderedBy: this.orderedByValue,
            });

        } catch (error) {
            return Promise.reject(error);
        }
    }
}
