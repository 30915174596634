
import { Component, Prop, Vue } from 'vue-property-decorator';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import Project from '@/models/Project';
// tslint:disable-next-line:max-line-length
import ClientAndContactPersonDetails from '@/components/views/NewProjectView/Overview/ClientAndContactPersonDetails.vue';
import OrderedByPicker from '@/components/views/NewProjectView/OrderedByPicker.vue';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'ClientDetails',
    components: {
        TwoRowWithTag,
        ClientAndContactPersonDetails,
        OrderedByPicker,
    },
})
export default class ClientDetails extends Vue {
    @Prop({ default: null }) private clientId!: string | null;
    @Prop({ default: null }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: false }) private basicProjectData!: any;

    public get canEditOrderedBy() {
        return this.userRights.includes(UserRightsEnum.AGENT_USER_RIGHT);
    }

    private get client() {
        if (this.project == null || this.project.client == null) {
            return null;
        }

        return this.project.client;
    }

    private get clientTags() {
        if (this.client == null) {
            return [];
        }
        return this.client.clientTypes.map((clientType) => {
            return {
                name: clientType.name,
                color: clientType.color,
            };
        });
    }

    private get contactPersonName() {
        if (!this.project) {
            return '-';
        }

        return this.project.contactPerson ? this.project.contactPerson.name : '-';
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }
}
