
import { Component, Prop, Vue } from 'vue-property-decorator';
import ClientRepository from '@/repositories/ClientRepository';
import { Select } from 'ant-design-vue';


@Component({
    name: 'ContactPersonPicker',
    components: {Select, SelectOption: Select.Option},
})
export default class ContactPersonPicker extends Vue {
    @Prop({required: true}) private selectedClientId!: string;
    @Prop({required: true}) private selectedContactPersonId!: string;

    private get contactPersons() {
        return ClientRepository.contactPersons(this.selectedClientId);
    }

    private onContactPersonSelect(contactPersonId: string) {
        this.$emit('update:selectedContactPersonId', contactPersonId);
    }

}
