var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"offerHistoryTableActionsWrapper"},[_c('Button',{attrs:{"disabled":!_vm.tableOfferItem.hasPDF,"data-test":"download-pdf","type":"link"},on:{"click":_vm.onDownloadOfferPDF}},[_c('Icon',{attrs:{"type":"file-pdf"}})],1),(_vm.isAnyItemActionVisible && !_vm.isPreviewMode)?_c('Dropdown',[_c('Menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.allItemActions),function(itemAction){return _c('MenuItem',{directives:[{name:"show",rawName:"v-show",value:(itemAction.isVisible),expression:"itemAction.isVisible"}],key:itemAction.key,attrs:{"data-test":"menu-item"},on:{"click":function($event){return _vm.proceedWithOfferAction({
                        params: {
                            offerId: _vm.tableOfferItem.id,
                            client: _vm.client,
                            projectId: _vm.projectId,
                            selectedOfferId: _vm.selectedOfferId,
                        },
                        key: itemAction.key,
                        callback: itemAction.callback,
                    })}}},[_vm._v(" "+_vm._s(itemAction.label)+" ")])}),1),_c('Button',{staticClass:"actions",attrs:{"data-test":"actions-dropdown","type":"link"}},[_c('Icon',{attrs:{"type":"more"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }