
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Select } from 'ant-design-vue';
import Client from '@/models/Client';
import { debounce } from 'vue-debounce';
import ClientRepository from '@/repositories/ClientRepository';
import { getFormattedClientAddress } from '@/helpers/NewProject/NewProjectHelper';

@Component({
    name: 'ClientSearch',
    components: {Select, SelectOption: Select.Option},
})
export default class ClientSearch extends Vue {
    @Prop({required: true}) private selectedClientId!: string | null;

    private debouncedSearch = debounce((value: string) => {
        this.onSearch(value);
    }, 300);

    private get clients() {
        const clientsInSearchResults = ClientRepository.getAllSearchResults();

        return clientsInSearchResults.length === 0 ?
            ClientRepository.getByIdInArray(this.selectedClientId) : clientsInSearchResults;
    }

    private async onSearch(value: string) {
        if (!value) {
            return;
        }
        await Client.searchNew(value);
    }

    private formatClientAddress(client: Client) {
        return getFormattedClientAddress(client);
    }

    private onChange(clientId: string) {
        this.$emit('update:selectedClientId', clientId);
    }
}
