
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Table, Tag } from 'ant-design-vue';
import {
    customOfferStateMapping,
    tableColumns,
    transformOfferListToTableList,
} from '@/helpers/OfferHistoryTableHelper';
import Offer from '@/models/Offer';
import { UserRepository } from '@/repositories/UserRepository';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import OfferHistoryTableActions from '@/components/views/project/OfferHistoryTableActions.vue';
import Client from '@/models/Client';
import { updateSentToClient } from '@/helpers/OfferHelper';
import { Action } from 'vuex-class';
import { OfferHistoryTableItem } from '@/interfaces/components/offerHistory/OfferHistoryTableItem';

@Component({
    name: 'OfferHistoryTable',
    components: { OfferHistoryTableActions, Table, Tag },
})
export default class OfferHistoryTable extends Vue {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;
    @Prop({ default: () => [] }) private offerList!: Offer[];
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ default: null }) private client!: Client | null;
    @Prop({ default: null }) private projectId!: string | null;
    @Prop({ default: false }) private isProjectLocked!: boolean;
    @Prop({ default: false }) private isProjectReadOnly!: boolean;
    @Prop({ default: false }) private isProjectFromAnotherUserGroup!: boolean;
    @Prop({ default: false }) private isPreviewMode!: boolean;
    @Prop({ default: false }) private isProjectInRequestState!: boolean;
    private selectedRowKey: Array<string | null> = [];

    private get stateTranslations() {
        return customOfferStateMapping();
    }

    private get columns() {
        return tableColumns(this.isPreviewMode);
    }

    private get transformedOfferList() {
        return transformOfferListToTableList(this.offerList, this.isProjectInRequestState);
    }

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private isRowHighlighted(offerHistoryTableItem: OfferHistoryTableItem) {
        return offerHistoryTableItem?.id === this.selectedOfferId ? 'isHighlighted' : undefined;
    }

    private defaultSelectedOfferId() {
        if (this.selectedOfferId) {
            return this.selectedOfferId;
        }

        return this.offerList.length > 0 ? this.offerList[0].id : null;
    }

    private onSelectChange(selectedRowKey: string[]) {
        this.$notification.info({
            message: this.$t('Druga ponuda je odabrana') as string,
            description: '',
        });

        EventBus.$emit(EventBusEvents.onUpdateSelectedOffer, {
            selectedOfferId: selectedRowKey[0],
        });
        Offer.setOfferToSelected(selectedRowKey[0]);
    }

    private async onSendToClient(e: Event, offerId: string) {
        if (e.target == null) {
            return;
        }
        this.updateAreOffersLoading(true);

        try {
            await updateSentToClient(offerId, (e.target as HTMLInputElement).checked);
        } finally {
            this.updateAreOffersLoading(false);
        }

        // todo - remove when the new design is implemented
        EventBus.$emit(EventBusEvents.updateOfferData);
    }

    @Watch('selectedOfferId', { immediate: true })
    private onSelectedOfferIdChange() {
        this.selectedRowKey = [this.defaultSelectedOfferId()];
    }
}
