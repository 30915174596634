
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { UserRepository } from '@/repositories/UserRepository';
import { recalculateOfferItems } from '@/helpers/project/ProductFormVersionHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import i18n from '@/i18n';
import DefaultValueWarnings from '@/components/global/popup/DefaultValueWarnings.vue';

@Component({
    name: 'RecalculationErrors',
    components: { DefaultValueWarnings },
})
export default class RecalculationErrors extends Vue {
    @Getter('configurator/offerItemRecalculationErrors')
    private offerItemRecalculationErrors!: any[];
    @Getter('configurator/callbackAfterCalculation')
    private callbackAfterCalculation!: (() => void) | null;
    @Getter('configurator/recalculationAndCallbackLabel')
    private recalculationAndCallbackLabel!: string;

    @Prop({ required: false }) private onUpdateProject!: () => Promise<void>;
    @Prop({ required: true }) private isProjectLocked!: boolean;
    @Prop({ required: true }) private isOfferLocked!: boolean;
    @Prop({ required: false, default: false }) private isOfferActionsPopup!: boolean;
    @Prop({ required: false }) private offerId!: string;

    private areUpdatesRunning = false;
    private showDefaultValueWarningsModal = false;
    private currentWarningsModalId = 0;

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private get isCalculationRunning() {
        return this.offerItemRecalculationErrors.some((offerItem) => offerItem.status === 'loading');
    }

    private toggleShowModal(id: number) {
        this.showDefaultValueWarningsModal = !this.showDefaultValueWarningsModal;
        this.currentWarningsModalId = id;
    }

    private async onRecalculateAndCallback() {
        if (this.callbackAfterCalculation == null) {
            return;
        }

        this.areUpdatesRunning = true;

        await recalculateOfferItems(this.offerId);
        if (this.onUpdateProject) {
            await this.onUpdateProject();
        }

        this.callbackAfterCalculation();

        this.areUpdatesRunning = false;
    }

    private async onRecalculate() {
        if (this.callbackAfterCalculation == null) {
            return;
        }

        this.areUpdatesRunning = true;

        await recalculateOfferItems(this.offerId);
        if (this.onUpdateProject) {
            await this.onUpdateProject();
        }

        this.areUpdatesRunning = false;

        EventBus.$emit(EventBusEvents.closeRecalculationPopup);
        EventBus.$emit(EventBusEvents.closePopup);

        this.$notification.success({
            message: `${i18n.t('Rekalkulacija je bila uspješna!')}`,
            description: '',
        });
    }

    private onCancel() {
        this.$emit('onCancel');
    }

    private closeModal() {
        this.showDefaultValueWarningsModal = false;
    }

    private mounted() {
        if (this.isProjectLocked || this.isOfferLocked) {
            this.$notification.error({
                message: this.$t('Zbog zastarjelih pozicija ne možete nastaviti raditi na ovoj ponudi') as string,
                description: this.$t('Molimo iskopirajte ponudu te onda odradite rekalkulaciju') as string,
            });
        }
    }
}
