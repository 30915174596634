
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ActiveStatus from '@/components/views/NewProjectView/Overview/ActiveStatus.vue';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import ProjectDetails from '@/components/views/NewProjectView/Overview/ProjectDetails.vue';

@Component({
    name: 'ProjectGeneralInfo',
    components: { ProjectDetails, ActiveStatus },
})
export default class ProjectGeneralInfo extends Vue {
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: false }) private basicProjectData!: any;

    private get isProjectInRequestState() {
        return (this.project?.state ?? this.basicProjectData?.state) === ProjectStates.REQUEST;
    }
}
