
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Icon } from 'ant-design-vue';


@Component({
    name: 'TwoRowTextWithIcon',
    components: {Icon},
})
export default class TwoRowTextWithIcon extends Vue {
    @Prop({default: null}) private icon!: string | null;
}
