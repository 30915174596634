
import { Component, Prop } from 'vue-property-decorator';
import Offer from '@/models/Offer';
import OfferHistoryTable from '@/components/views/project/OfferHistoryTable.vue';
import Client from '@/models/Client';
import OfferHistoryActions from '@/components/views/project/OfferHistoryActions.vue';
import ContactPerson from '@/models/ContactPerson';
import Project from '@/models/Project';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import RecalculationErrors from '@/components/views/project/RecalculationErrors.vue';
import { OfferItemsErrors } from '@/interfaces/components/projectNew/OfferItemsErrors';

@Component({
    name: 'OfferHistoryNew',
    components: { OfferHistoryActions, OfferHistoryTable, RecalculationErrors },
})
export default class OfferHistoryNew extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: false }) private shouldShowOnlyCreateOfferButton!: boolean;
    @Prop({ default: false }) private isPreviewMode!: boolean;
    @Prop({ default: true }) private shouldShowTitle!: boolean;
    @Prop({ required: true }) private offerList!: Offer[];
    @Prop({ default: true }) private isExpandedMode!: boolean;
    @Prop({ default: null }) private client!: Client | null;
    @Prop({ default: null }) private selectedOffer!: Offer | null;
    @Prop({ default: null }) private projectId!: string | null;
    @Prop({ default: null }) private project!: Project | null;
    @Prop({ default: null }) private contactPerson!: ContactPerson | null;
    @Prop({ default: '' }) private projectConnection!: string;
    @Prop({ required: false }) private onUpdateProject!: () => Promise<void>;
    @Prop({ default: false }) private isRecalculationModalVisible!: boolean;
    @Prop({ default: false }) private isOfferActionsPopup!: boolean;

    private get isProjectInRequestState() {
        if (this.project == null) {
            return false;
        }
        return this.project.projectState === 'request';
    }

    private get isProjectLocked() {
        if (this.project == null) {
            return false;
        }
        if (String(this.project.userGroupId) !== String(this.$store.getters['jwtData/currentUserGroup'])) {
            return true;
        }
        return this.project.projectState === 'order';
    }

    private get isProjectReadOnly() {
        if (this.project == null) {
            return null;
        }
        return this.project.readOnly;
    }

    private get isRoltek(): boolean {
        const userGroup = String(this.$store.getters['jwtData/currentUserGroup']);

        if (userGroup === '1' || userGroup === '2') {
            return true;
        }

        return false;
    }

    private get isProjectFromAnotherUserGroup() {
        if (this.project == null || this.currentUserGroup == null) {
            return false;
        }

        return String(this.project.userGroupId) !== this.currentUserGroup.id;
    }

    private get isOfferListEmpty() {
        return this.offerList.length <= 0;
    }

    private get controlledOfferList() {
        return this.isExpandedMode ? this.offerList : [this.offerList[0]];
    }

    private get isOfferLocked() {
        if (this.selectedOffer == null) {
            return false;
        }
        return this.selectedOffer.offerPdf != null;
    }

    private get offerHasErrors() {
        const offerErrorState = this.$store.getters['configurator/offerErrorState'];

        return offerErrorState.existingError;
    }

    private get selectedOfferAssistanceRequired() {
        if (this.selectedOffer == null) {
            return false;
        }

        return this.selectedOffer.assistanceRequired;
    }
    private closeRecalculationModal() {
        EventBus.$emit(EventBusEvents.closeRecalculationPopup);
    }
}
