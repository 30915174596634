
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Tooltip } from 'ant-design-vue';
import OfferRepository from '@/repositories/OfferRepository';
import { openDocumentAndPdfTypePopup } from '@/helpers/OfferHelper';

@Component({
    name: 'CreateOfferButton',
    components: { Button, Tooltip },
})
export default class CreateOfferButton extends Vue {
    @Prop({ default: false }) private offerHasErrors!: boolean;
    @Prop({ default: true }) private showIcon!: boolean;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ default: null }) private projectId!: string | null;

    private async openDocumentAndPdfTypePopup() {
        const offer = OfferRepository.getOfferById(this.selectedOfferId);

        if (offer == null || this.projectId == null) {
            return;
        }

        await openDocumentAndPdfTypePopup(this.projectId, offer);
    }
}
