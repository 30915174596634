
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import TwoRowTextWithIcon from '@/components/global/TwoRowTextWithIcon.vue';
import CommentIcon from '@/components/icons/CommentIcon.vue';
import { getSelectedOfferPrice } from '@/helpers/NewProject/NewProjectHelper';

@Component({
    name: 'ProjectDetails',
    components: { TwoRowTextWithIcon, CommentIcon },
})
export default class ProjectDetails extends Vue {
    @Prop({ required: true }) private project!: Project;

    private get numberOfChatMessages() {
        return this.project.chat ? this.project.chat.messages.length : 0;
    }

    private get selectedOfferPrice() {
        return getSelectedOfferPrice(this.project.projectId);
    }
}
