
import { Component, Prop, Vue } from 'vue-property-decorator';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import ClientDetails from '@/components/views/NewProjectView/Overview/ClientDetails.vue';
import ClientForm from '@/components/views/NewProjectView/Overview/ClientForm.vue';
import Project from '@/models/Project';

@Component({
    name: 'ClientDetailsAndForm',
    components: { ClientForm, ClientDetails, TwoRowWithTag },
})
export default class ClientDetailsAndForm extends Vue {
    @Prop({ default: null }) private clientId!: string | null;
    @Prop({ required: true }) private contactPersonId!: string | null;
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: false }) private basicProjectData!: any;
}
